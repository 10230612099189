import React from 'react'
import "./styles/contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <h1>Contact me!</h1>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.2482794024145!2d121.09435586092115!3d14.698546738079434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397ba6b5e8bfcfb%3A0x5163dd2c6956551a!2s28%20Santan%2C%20Quezon%20City%2C%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1618040572885!5m2!1sen!2sph" allowfullscreen="" loading="lazy"></iframe>

      <h4>Phone:</h4>
      <p>09491786317</p>

      <h4>Email:</h4>
      <p>bendeloyola2017@gmail.com</p>

      <h4>Address:</h4>
      <p>28 Santan St. Quezon City, Philippines</p>
    </div>
  );
};

export default Contact;