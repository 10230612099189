import React from 'react'

import "./styles/works.css";

function Works(){  

  return (
    <div className="work-content">
      <div className="works">
        <h1>Experiences</h1>
        <p>Company: Fourthshift Global Inc</p>
        <p>Role: Web Developer</p>
        <p>Date: 2016 - 2017</p>
        <p>
        (html5, css3, js, bootstrap and codeigniter)
        </p>
        <br />
        <p>Company: GM YI XIN Holdings Inc</p>
        <p>Role: Linux Support Administrator / IT Support</p>
        <p>Date: 2017 - 2019</p>
        <ul>
          <li>Install and configure linux system</li>
          <li>Perform System Maintenance</li>
          <li>Create System Backups</li>
          <li>Analytical skills and able to examine error logs, system performance data</li>
          <li>Software Troubleshooting</li>
          <li>Installing Windows System </li>
          <li>Computer Hardware </li>
        </ul>
        <br/>
      </div>
      <div className="freelance">
        <h1>Freelance</h1>
        <a href="https://www.anohanapmo.ph" target="_blank"><p>anohanapmo.ph</p></a>
        <a href="https://www.philex.ph" target="_blank"><p>philex.ph</p></a>
        <a href="https://www.omgprofitshares.com"><p>omgprofitshares.com</p></a>
        <a href="https://tychecomputer.com/"><p>tychecomputer.com</p></a>
        <p>Date: 2020 - 2021</p>
        <br/>
        <a href="https://www.infinitiveprime.com"><p>infinitiveprime.com</p></a>
        <p>Date: 2015 - 2016</p>
      </div>
    </div>
  );
};

export default Works;