import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import "./styles/navbar.css"

function NavBar(){
    const [open, setOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(0);

    const location = useLocation()

    const trackScreenWidth = () => {
        const width = window.innerWidth;
        setScreenWidth(width);
        if (width > 600) {
            setOpen(true);
        }
    };

    const handleClose = () => {
      if (screenWidth < 600) {
        setOpen(false);
      }
    };

    useEffect(() => {
        trackScreenWidth();
        window.addEventListener("resize", trackScreenWidth);
        return () => window.removeEventListener("resize", trackScreenWidth);
    }, []);

  return (
    <nav className="navbar">
      <div className="nav-wrapper">
        <div className="logo">
            <Link to="/">
                <img
                src={`${process.env.PUBLIC_URL}/icon/react-blue.png`}
                alt="react-logo"
                />
            </Link>
        </div>
        <div className="list-wrapper">
          <img
            src={`${process.env.PUBLIC_URL}/icon/menu.png`}
            alt="Menu bars"
            style={{ opacity: !open ? 1 : 0}}
            onClick={() => {
                setOpen(!open)
            }}
          />
          <img
            src={`${process.env.PUBLIC_URL}/icon/x-mark.png`}
            alt="Menu cross"
            style={{ opacity: open ? 1 : 0}}
            onClick={() => {
                setOpen(!open)
            }}
          />

          <ul style={{ left: open ? "0" : "-100vw" }}>
            <li>
              <Link onClick={handleClose} style={{ color: location.pathname === "/" && "#00D8FF" }}
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link onClick={handleClose} style={{ color: location.pathname === "/about" && "#00D8FF" }}
                to="/about"
              >
                About
              </Link>
            </li>
            <li>
              <Link onClick={handleClose} style={{ color: location.pathname === "/skills" && "#00D8FF" }}
                to="/skills"
              >
                Skills
              </Link>
            </li>
            <li>
              <Link onClick={handleClose} style={{ color: location.pathname === "/works" && "#00D8FF" }}
                to="/works"
              >
                Works
              </Link>
            </li>
            <li>
              <Link onClick={handleClose} style={{ color: location.pathname === "/contact" && "#00D8FF" }}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;