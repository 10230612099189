import { Link, useLocation } from "react-router-dom";

// style
import './styles/home.css'

const Home = () => {
  return (
    <div className="home">
      <div className="title">
        <h1>
          <p>Hi, I'm Ben.</p>
          <p>Web Developer,</p>
          <p>Nice to meet you.</p>
        </h1>
        <Link to="/about">
          <button>More Info</button>
        </Link>
      </div>
      <div className="person">
        <img
          src={`${process.env.PUBLIC_URL}/ben-no-bg.png`}
          alt="person picture"
        />
      </div>
    </div>
  );
};

export default Home;