import React from 'react'
import './styles/about.css'

const About = () => {
    return (
        <div className="about">
            <h1>About Me </h1>
            <p>
            I am a hard-working Web Developer with a flair for creating elegant 
            solutions in the least amount of time. Looking for growth 
            opportunities to try new technologies and grow my technical 
            skill set in a team-based atmosphere.
            </p>
            <p>If i have some time, i love to watch videos about programming and try it at the same time, experiment and keep learning. currently i still learning in react js, that's why i built this my portfolio using that javascript library, and i want to have more experience using the library.
                if i have a chance to get a project in react, that would be great and i will push myself to go on that project and build it, like others project i have done.
            </p>
            <br/>
           
        </div>
    )
}

export default About