import React from 'react'

import './styles/footer.css'

const Footer = () => {
    return (
      <footer>
        <a href="https://www.facebook.com/bhenjon.deloyola" target="_blank">
          <img
            src={`${process.env.PUBLIC_URL}/icon/facebook.png`}
            alt="Facebook-icon"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/ben-de-loyola-11418a17a/"
          target="_blank"
        >
          <img
            src={`${process.env.PUBLIC_URL}/icon/linkedin.png`}
            alt="LinkIn-icon"
          />
        </a>
        <a href="https://github.com/bendeloyola" target="_blank">
          <img
            src={`${process.env.PUBLIC_URL}/icon/github.png`}
            alt="github-icon"
          />
        </a>
      </footer>
    );
  };
  
  export default Footer;