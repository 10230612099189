import React from 'react'

import "./styles/skills.css";

function Skills(){
  return (
    <div className="skills">
      <h1>Skills</h1>
      <div className="content">
        <div className="tools">
          <strong>Tools:</strong>
          <li>Visual Studio Code</li>
          <li>Git & Github</li>
          <li>Twitter Bootstrap</li>
          <li>jQuery</li>
          <li>Chrome Developer Tools</li>
          <li>Sass</li>
        </div>
        <div className="languages">
          <strong>Languages:</strong>
          <li>CSS3</li>
          <li>HTML5</li>
          <li>JavaScript</li>
          <li>PHP</li>
          <li>React js</li>
        </div>
        <div className="libraries">
          <strong>Libraries & Framework:</strong>
          <li>Bootstrap</li>
          <li>React.js</li>
          <li>Codeigniter</li>
        </div>
      </div>
    </div>
  );
};

export default Skills;